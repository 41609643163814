import axios from "axios";

export default axios.create({
  /*
  baseURL: "https://airbank-talking-camel.addai.cz",
  baseURL: "https://talking-camel.addai.cz",
  */
 baseURL: "https://testing-adam.addai.cz/ck",
  headers: {
    'Content-Type': "application/json",
  }
});
