import React from "react";
//import TalkingCamel from "../../api/TalkingCamel";
import "./Dashboard.scss";
//import RefreshToken from "../../api/RefreshToken";
import ReactDOM from "react-dom";
import Popup from "../../components/popup/Popup";
import Loading from "../loading/Loading";
import GetProjectVersion from "../getProjectVersion/GetProjectVersion";
import DashboardSOData from "./dashboardSOData/DashboardSOData";
import CoachingKangaroo from "../../api/CoachingKangaroo";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
 // ResponsiveContainer,
} from "recharts";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project,
      projectPrev: "",
      data: [
        {
          name: "Page A",
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: "Page B",
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: "Page C",
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: "Page D",
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: "Page E",
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: "Page F",
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: "Page G",
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getProjectVersion();
    //this.getConverstations();
  };

  componentDidUpdate = () => {
    /*if (this.state.project !== this.state.projectPrev) {
      this.getConverstations();
      if (this.state.project !== this.state.projectPrev) {
        this.getProjectVersion();
      }
    }*/
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      project: nextProps.project,
    };
  }

  getConverstations = (offset) => {
    CoachingKangaroo.get(
      "/api/training/v1/" +
        "Addai%20Bank%20Demo" +
        "/conversations",
      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        console.log(response);
        //RefreshToken();
        this.setState({
          conversations: response.data.conversations,
          loading: false,
          projectPrev: this.state.project,
        });
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error) {
          if (error.response) {
            if (error.response.data) {
              if (error.response.data[0]) {
                if (error.response.data[0].error) {
                  errorMessage = error.response.data[0].error;
                } else {
                  errorMessage = "Unknown Error SpravaOdpovedi";
                }
              } else {
                errorMessage = "Unknown Error SpravaOdpovedi";
              }
            } else {
              errorMessage = "Unknown Error SpravaOdpovedi";
            }
          } else {
            errorMessage = "Unknown Error SpravaOdpovedi";
          }
        } else {
          errorMessage = "Unknown Error SpravaOdpovedi";
        }
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  parseConversationData = () => {
    this.state.conversations.map((data) => {
      if (data.status === "OK") {
        this.setState({
            statusOk: this.state.statusOk + 1,
        });
      }
      if (data.status === "CHANGE_REQUEST") {
        this.setState({
            statusChangeReq: this.state.statusChangeReq + 1,
        });
      }
      if (data.status === "CHANGE_REQUEST_PROCESSED") {
        this.setState({
            statusChangeProcess: this.state.statusChangeProcess + 1,
        });
      }
    });
  };

  getProjectVersion = () => {
    this.setState({
      versionProd: GetProjectVersion(this.state.project, this.state.projectPrev)
        .dataVersionProduction,
      versionDev: GetProjectVersion(this.state.project, this.state.projectPrev)
        .dataVersionDevelopment,
      projectPrev: this.state.project,
    });
  };

  renderTitle = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h6 className="title-secondary">
              Prod version: {this.state.versionProd}
            </h6>
          </div>
          <div className="col">
            <h4 className="title-main">Dashboard</h4>
          </div>
          <div className="col">
            <h6 className="title-secondary">
              Dev version: {this.state.versionDev}
            </h6>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="Dashboard-wrap">
          <div className="Dashboard-renderPrehled">
            {this.renderTitle()}
            <Loading />
          </div>
        </div>
      );
    } else {
      return (
        <div className="Dashboard-wrap">
          <div className="Dashboard-renderPrehled">
            {this.renderTitle()} - loaded
          </div>
          <div className="Dashboard-chart">
            <LineChart
              width={500}
              height={300}
              data={this.state.data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </div>
          <div>
            <DashboardSOData
              userRole={this.props.userRole}
              project={this.props.project}
              accessToken={this.props.accessToken}
            />
          </div>
        </div>
      );
    }
  }
}

export default Dashboard;
