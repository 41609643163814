import "./GetProjectVersion.scss";

const GetProjectVersion = (project, projectPrev) => {
  if (window.localStorage.getItem("data")) {
    const projectInfo = JSON.parse(window.localStorage.getItem("data")).projects;
    const result = projectInfo.find( ({ id }) => id === project );
    return result ;   
  }
};

export default GetProjectVersion;
