import TalkingCamel from "./TalkingCamel";
import ReactDOM from "react-dom";
import Popup from "../components/popup/Popup";
import React from "react";

//parseInt(sessionTimeCheck) + 270 < Math.ceil(parseInt(Date.now()) / 1000)

const refreshToken = () => {
  //window.sessionStorage.setItem("accessTime", Date.now())
  TalkingCamel.post("/api/auth/token", {
    "refresh-token": window.localStorage.getItem("refreshToken"),
  })
    .then((response) => {
      //console.log("refresh token");
      window.localStorage.setItem("accessToken", response.data["access-token"])
      window.localStorage.setItem("refreshToken", response.data["refresh-token"])
    })
    .catch((error) => {
      //console.log("refresh fail");
      ReactDOM.render(
        <Popup text={error.response.data.message} />,
        document.getElementById("popup-window")
      );
    });
};

export default refreshToken;