import "./ProjectSelect.scss";
import React from "react";

class ProjectSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderProjects = () => {
    let projects = "";
    window.localStorage.getItem("data")
      ? (projects = JSON.parse(window.localStorage.getItem("data")).projects)
      : (projects = "");
    let i = 0;
    if (window.localStorage.getItem("data")) {
      const projectsList = projects.map((projects) => {
        i++;
        return (
          <option
            key={"option_" + i}
            value={projects.id}
            valuename={projects.name}
          >
            {projects.name}
          </option>
        );
      });
      return (
        <select
          onChange={(e) =>
            this.props.onProjectSelect(e.target.value, e.target.valuename)
          }
          className="form-control"
        >
          {projectsList}
        </select>
      );
    }
    return "";
  };

  render() {
    return <div className="ProjectSelect">{this.renderProjects()}</div>;
  }
}

export default ProjectSelect;
