import React from "react";
import TalkingCamel from "../../../api/TalkingCamel";
import "../Dashboard.scss";
import RefreshToken from "../../../api/RefreshToken";
import ReactDOM from "react-dom";
import Popup from "../../../components/popup/Popup";
import Loading from "../../loading/Loading";
import GetProjectVersion from "../../getProjectVersion/GetProjectVersion";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project,
      projectPrev: "",
     
    };
  }

  componentDidMount = () => {
    this.getProjectVersion();
    this.getDataSOPreview();
  };

  componentDidUpdate = () => {
    if (this.state.project !== this.state.projectPrev) {
      this.getDataSOPreview();
      if (this.state.project !== this.state.projectPrev) {
        this.getProjectVersion();
      }
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      project: nextProps.project,
    };
  }

  getDataSOPreview = () => {
    TalkingCamel.get("/api/dialog-texts/v2/" + this.state.project, {
      headers: {
        Authorization: "Bearer " + this.props.accessToken,
      },
    })
      .then((response) => {
        console.log(response);
        RefreshToken();
        this.setState({
          dataSOPreview: response.data,
          loading: false,
          projectPrev: this.state.project,
        });
        this.parseDataSOPreview();
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error) {
          if (error.response) {
            if (error.response.data) {
              if (error.response.data[0]) {
                if (error.response.data[0].error) {
                  errorMessage = error.response.data[0].error;
                } else {
                  errorMessage = "Unknown Error SpravaOdpovedi";
                }
              } else {
                errorMessage = "Unknown Error SpravaOdpovedi";
              }
            } else {
              errorMessage = "Unknown Error SpravaOdpovedi";
            }
          } else {
            errorMessage = "Unknown Error SpravaOdpovedi";
          }
        } else {
          errorMessage = "Unknown Error SpravaOdpovedi";
        }
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  getProjectVersion = () => {
    this.setState({
      versionProd: GetProjectVersion(this.state.project, this.state.projectPrev)
        .dataVersionProduction,
      versionDev: GetProjectVersion(this.state.project, this.state.projectPrev)
        .dataVersionDevelopment,
      projectPrev: this.state.project,
    });
  };

  parseDataSOPreview = () => {
    this.setState({
        total:0,
        statusOk:0,
        statusChangeReq:0,
        statusChangeProcess:0,
    })
    this.state.dataSOPreview.map((data) => {
      this.setState({
        total: this.state.total + 1,
    });
      if (data.status === "OK") {
        this.setState({
            statusOk: this.state.statusOk + 1,
        });
      }
      if (data.status === "CHANGE_REQUEST") {
        this.setState({
            statusChangeReq: this.state.statusChangeReq + 1,
        });
      }
      if (data.status === "CHANGE_REQUEST_PROCESSED") {
        this.setState({
            statusChangeProcess: this.state.statusChangeProcess + 1,
        });
      }
    });
  };

  static demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";

  render() {
    if (this.state.loading) {
      return (
        <div className="dashboardSOData-wrap">
          <div className="dashboardSOData-renderPrehled">
            <Loading />
          </div>
        </div>
      );
    } else {
      return (
        <div className="dashboardSOData-wrap">
          <div className="dashboardSOData-renderPrehled">
            <div>Total Dialogs:{this.state.total}</div>
            <div>Status OK:{this.state.statusOk}</div>
            <div>Change requested:{this.state.statusChangeReq}</div>
            <div>Change processed:{this.state.statusChangeProcess}</div>
          </div>
        </div>
      );
    }
  }
}

export default Dashboard;
