import React from "react";
import TalkingCamel from "../../api/TalkingCamel";
import "./SpravaOdpovedi.scss";
import SpravaOdpovediPrehled from "../spravaOdpovedi/spravaOdpovediPrehled/SpravaOdpovediPrehled";
import RefreshToken from "../../api/RefreshToken";
import ReactDOM from "react-dom";
import Popup from "../../components/popup/Popup";
import Loading from "../loading/Loading";
import GetProjectVersion from "../getProjectVersion/GetProjectVersion";
import SpravaOdpovediNewDialog from "./spravaOdpovediNewDialog/SpravaOdpovediNewDialog";
import SpravaOdpovediNewDialogCreate from "./spravaOdpovediNewDialog/spravaOdpovediNewDialogCreate/SpravaOdpovediNewDialogCreate";
import SwitchIOS from "../materialUI/switchIOS/SwitchIOS";

import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

class SpravaOdpovedi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogShort: [],
      term: "",
      project: this.props.project,
      projectPrev: "",
      loading: true,
      dialogNewShow: false,
      dialogNewCreate: false,
      extendedSearchText: false,
      extendedTerm: "",
      platformsListUnfiltered: [],
      platformsListFiltered: [],
      platformsListForFiltering: [],
      requestStatus: "",
      newDialoguesCount: 0,
      changeRequestProcessedCount: 0,
      changeRequestCount: 0,
    };
  }

  componentDidMount = () => {
    this.getSOPreview();
    this.getProjectVersion();
    this.getSODialogNewPreview();
  };

  componentDidUpdate = () => {
    if (this.state.project !== this.state.projectPrev) {
      this.getSOPreview();
      this.getSODialogNewPreview();
      if (this.state.project !== this.state.projectPrev) {
        this.getProjectVersion();
      }
    }
  };

  getProjectVersion = () => {
    this.setState({
      versionProd: GetProjectVersion(this.state.project, this.state.projectPrev)
        .dataVersionProduction,
      versionDev: GetProjectVersion(this.state.project, this.state.projectPrev)
        .dataVersionDevelopment,
      projectPrev: this.state.project,
    });
  };

  toggleState = (input_kind) => {
    const name = "state" + input_kind;
    this.setState(
      () => ({
        [name]: this.state[name] === true ? false : true,
      }),
      input_kind === "extendedSearchText"
        ? () => {
            this.getSOPreview();
          }
        : ""
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      project: nextProps.project,
    };
  }

  getSOPreview = () => {
    TalkingCamel.get(
      "/api/dialog-texts/v2/" +
        this.state.project +
        (this.state.extendedSearchText
          ? "?search=" + this.state.extendedTerm
          : ""),
      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        //console.log(response);
        RefreshToken();
        this.setState(
          {
            dialogShort: response.data,
            loading: false,
            projectPrev: this.state.project,
          },
          () => {
            this.platformsForFilter();
          }
        );
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error) {
          if (error.response) {
            if (error.response.data) {
              if (error.response.data[0]) {
                if (error.response.data[0].error) {
                  errorMessage = error.response.data[0].error;
                } else {
                  errorMessage = "Unknown Error SpravaOdpovedi";
                }
              } else {
                errorMessage = "Unknown Error SpravaOdpovedi";
              }
            } else {
              errorMessage = "Unknown Error SpravaOdpovedi";
            }
          } else {
            errorMessage = "Unknown Error SpravaOdpovedi";
          }
        } else {
          errorMessage = "Unknown Error SpravaOdpovedi";
        }
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  getSODialogNewPreview = () => {
    TalkingCamel.get(
      "/api/dialog-texts/v2/" + this.props.project + "/dialog-new",
      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        this.setState({
          newDialoguesCount: response.data.length,
        });
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error) {
          if (error.response) {
            if (error.response.data) {
              if (error.response.data[0]) {
                if (error.response.data[0].error) {
                  errorMessage = error.response.data[0].error;
                } else {
                  errorMessage = "Unknown Error SpravaOdpovedi";
                }
              } else {
                errorMessage = "Unknown Error SpravaOdpovedi";
              }
            } else {
              errorMessage = "Unknown Error SpravaOdpovedi";
            }
          } else {
            errorMessage = "Unknown Error SpravaOdpovedi";
          }
        } else {
          errorMessage = "Unknown Error SpravaOdpovedi";
        }
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  updateStatus = () => {
    this.getSOPreview();
  };

  onSearchInputSubmit = (e) => {
    e.preventDefault();
  };

  showSearch = (searchCrit) => {
    if (
      !this.state.extendedSearchText &&
      !this.state.dialogNewShow &&
      !this.state.dialogNewCreate
    ) {
      return (
        <div>
          <div className="Search-wrap container">
            <div className="form-group row">
              <input
                type="text"
                id="Search-input"
                placeholder="Search by title or ID"
                className="form-control col-9"
                value={this.state.term}
                onChange={(e) => this.setState({ term: e.target.value })}
              />
              {searchCrit === "DM" ? this.textSearchToggleButton() : ""}
            </div>
          </div>
          {searchCrit === "DM" ? this.extendedSearch() : ""}
        </div>
      );
    } else if (!this.state.dialogNewShow && !this.state.dialogNewCreate) {
      return (
        <div>
          <div className="Search-wrap form-group container">
            <form
              className=" row"
              onSubmit={(e) => this.submitExtendedSearch(e)}
            >
              <input
                type="text"
                id="Search-input"
                placeholder="Search by text in dialog"
                className="form-control col-9"
                value={this.state.extendedTerm}
                onChange={(e) => this.sendTextsearch(e)}
              />
              {searchCrit === "DM" ? this.textSearchToggleButton() : ""}
            </form>
          </div>
          {searchCrit === "DM" ? this.extendedSearch() : ""}
        </div>
      );
    }
  };

  sendTextsearch = (e) => {
    this.setState({ extendedTerm: e.target.value }, () => {
      setTimeout(() => {
        this.getSOPreview();
      }, 1000);
    });
  };

  textSearchToggleButton = () => {
    return (
      <div className="Search-checkboxes-checkbox col-3">
        <SwitchIOS
          switchUsed={this.extendedSearchText}
          switchCurrentState={this.state.extendedSearchText}
          switchLabel={"Search in text"}
        />
      </div>
    );
  };

  extendedSearchText = () => {
    this.state.extendedSearchText
      ? this.setState({ extendedSearchText: false }, () => {
          this.getSOPreview();
        })
      : this.setState({ extendedSearchText: true }, () => {
          this.getSOPreview();
        });
  };

  platformsForFilter = () => {
    if (this.state.dialogShort) {
      let i = 0;
      let platformsData = this.state.dialogShort;
      const platformsDataConst = platformsData.map(
        (platformArray) =>
          new Promise((resolve, reject) => {
            try {
              i++;
              platformArray.platforms.map((platform) => {
                this.setState((prevState) => ({
                  platformsListUnfiltered: [
                    ...prevState.platformsListUnfiltered,
                    platform,
                  ],
                }));
                resolve(this.state.platformsListUnfiltered);
              });
            } catch (error) {
              console.log(error);
              resolve(null);
            }
          })
      );
      Promise.all(platformsDataConst).then(() => {
        this.filterPlatformList(i);
        this.changeRequestCount();
      });
    }
  };

  filterPlatformList = (i) => {
    let platformsData = this.state.dialogShort;
    if (platformsData.length === i) {
      const platformsListFiltered = [
        ...new Set(this.state.platformsListUnfiltered),
      ];
      this.setState({ platformsListFiltered: platformsListFiltered });
    }
  };

  handleChangeRequest = (event) => {
    this.setState({ requestStatus: event.target.value });
  };

  extendedSearch = () => {
    return (
      <div className="container">
        <div className="row requestStatus-simple-select">
          <div className="col-filters">
            <FormControl>
              <InputLabel id="requestStatus-select-label">
                Dialog Status
              </InputLabel>
              <Select
                labelId="requestStatus-select-label"
                id="requestStatus-simple-select"
                value={this.state.requestStatus}
                onChange={(e) => this.handleChangeRequest(e)}
              >
                <MenuItem value="ALL">All</MenuItem>
                <MenuItem value="OK">OK</MenuItem>
                <MenuItem value="CHANGE_REQUEST">Change request</MenuItem>
                <MenuItem value="CHANGE_REQUEST_PROCESSED">
                  Change request processed
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-filters">{this.renderPlatformFilter()}</div>
        </div>
        <div className="Search-checkboxes row"></div>
      </div>
    );
  };

  renderPlatformFilter = () => {
    if (this.state.platformsListFiltered) {
      let i = 0;
      let platforms = this.state.platformsListFiltered;
      const platformsFilter = platforms.map((platform) => {
        i++;
        return (
          <MenuItem key={platform + "_" + i} value={platform}>
            <Checkbox
              checked={
                this.state.platformsListForFiltering.indexOf(platform) > -1
              }
              onChange={(e) => this.sendPlatformToFiltering(platform)}
            />
            <ListItemText primary={platform} />
          </MenuItem>
        );
      });
      return (
        <FormControl>
          <InputLabel id="demo-mutiple-checkbox-label">
            Filter by platform
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={this.state.platformsListForFiltering}
            input={<Input />}
            renderValue={(selected) => selected.join(", ")}
          >
            {platformsFilter}
          </Select>
        </FormControl>
      );
    }
  };

  sendPlatformToFiltering = (platform) => {
    if (
      this.state.platformsListForFiltering.includes(platform) &&
      this.state.platformsListForFiltering.length !== 1
    ) {
      let platformsListForFilteringState = this.state.platformsListForFiltering;
      platformsListForFilteringState
        .filter((element) => {
          if (element === platform) {
            return false;
          } else {
            return true;
          }
        })
        .map((data) => {
          if (!data) {
            this.setState({ platformsListForFiltering: [] });
          } else {
            this.setState({ platformsListForFiltering: [data] });
          }
        });
    } else if (
      this.state.platformsListForFiltering.includes(platform) &&
      this.state.platformsListForFiltering.length === 1
    ) {
      this.setState({ platformsListForFiltering: [] });
    } else {
      this.setState((prevState) => ({
        platformsListForFiltering: [
          ...prevState.platformsListForFiltering,
          platform,
        ],
      }));
    }
  };

  submitExtendedSearch = (e) => {
    e.preventDefault();
    this.getSOPreview();
  };

  changeRequestCount = () => {
    let data = this.state.dialogShort;
    let i = 0;
    let changeRequest = 0;
    let changeRequestProcessed = 0;
    let changeRequestCountDataLength = data.length;
    data.map((data) => {
      i++;
      if (data.status === "CHANGE_REQUEST") {
        changeRequest++;
      }
      if (data.status === "CHANGE_REQUEST_PROCESSED") {
        changeRequestProcessed++;
      }
      if (changeRequestCountDataLength === i) {
        this.setState({
          changeRequestCount: changeRequest,
          changeRequestProcessedCount: changeRequestProcessed,
        });
      }
    });
  };

  renderSidebar = () => {
    let dialogues = this.state.dialogShort;
    const dialoguesTotal = dialogues.length;
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="title-secondary bold">Versions</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="title-secondary bold">
              Prod version: {this.state.versionProd}
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="title-secondary bold">
              Dev version: {this.state.versionDev}
            </h6>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="title-secondary">
              Dialogues total: {dialoguesTotal}
            </h6>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="title-secondary">
              Change request:{this.state.changeRequestCount}
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="title-secondary">
              Change request processed: {this.state.changeRequestProcessedCount}
            </h6>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h6 className="title-secondary">
              New dialogues:{this.state.newDialoguesCount}
            </h6>
            <br />
          </div>
        </div>
        <div className="row switchMenu-row">
          <div className="col">{this.switchMenu()}</div>
        </div>
      </div>
    );
  };

  renderTitle = () => {
    return (
      <div className="projectName">
        <h2>{this.props.projectName}</h2>
      </div>
    );
  };

  switchMenu = () => {
    if (this.state.dialogNewShow) {
      return (
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={(e) => this.setState({ dialogNewShow: false })}
        >
          Dialog management
        </Button>
      );
    } else {
      return (
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={(e) => this.setState({ dialogNewShow: true })}
        >
          + New dialog
        </Button>
      );
    }
  };

  switchCreateOrList = () => {
    if (
      this.props.userRole.includes("CUSTOMER_EDITOR:" + this.props.project) ||
      this.props.userRole.includes("EDITOR:" + this.props.project) ||
      this.props.userRole.includes("ADMIN")
    ) {
      return (
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={(e) =>
            this.setState({
              dialogNewCreate: this.state.dialogNewCreate ? false : true,
            })
          }
        >
          {this.state.dialogNewCreate ? "Return to list" : "Create new dialog"}
        </Button>
      );
    }
  };

  switchDialogNewCreateState = () => {
    this.setState({
      dialogNewCreate: this.state.dialogNewCreate ? false : true,
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="SpravaOdpovedi-wrap">
          <div className="SpravaOdpovedi-renderPrehled">
            <div className="row renderTopRow">
              <div className="col title">{this.renderTitle()}</div>
            </div>
            <div className="SpravaOdpovedi-renderSidebar">
              {this.renderSidebar()}
            </div>
            <Loading />
          </div>
        </div>
      );
    } else {
      if (!this.state.dialogNewShow) {
        return (
          <div className="SpravaOdpovedi-wrap">
            <div className="SpravaOdpovedi-renderPrehled">
              <div className="row renderTopRow">
                <div className="col title">{this.renderTitle()}</div>
              </div>
              <div className="SpravaOdpovedi-renderSidebar">
                {this.renderSidebar()}
              </div>
              {this.showSearch("DM")}
              <SpravaOdpovediPrehled
                updateStatus={this.updateStatus}
                userRole={this.props.userRole}
                project={this.state.project}
                dialogShort={this.state.dialogShort}
                searchedTerm={this.state.term}
                accessToken={this.props.accessToken}
                username={this.props.username}
                requestStatus={this.state.requestStatus}
                platformsListForFiltering={this.state.platformsListForFiltering}
              />
            </div>
          </div>
        );
      } else {
        if (!this.state.dialogNewCreate) {
          return (
            <div className="SpravaOdpovedi-wrap">
              <div className="SpravaOdpovedi-renderPrehled">
                <div className="row renderTopRow">
                  <div className="col title">{this.renderTitle()}</div>
                  <div className="col button">
                    {this.state.dialogNewShow ? this.switchCreateOrList() : ""}
                  </div>
                </div>
                <div className="SpravaOdpovedi-renderSidebar">
                  {this.renderSidebar()}
                </div>
                {this.showSearch("DMN")}
                <SpravaOdpovediNewDialog
                  project={this.state.project}
                  accessToken={this.props.accessToken}
                  userRole={this.props.userRole}
                  searchedTerm={this.state.term}
                  username={this.props.username}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div className="SpravaOdpovedi-wrap">
              <div className="SpravaOdpovedi-renderPrehled">
                <div className="row renderTopRow">
                  <div className="col title">{this.renderTitle()}</div>
                  <div className="col button">
                    {this.state.dialogNewShow ? this.switchCreateOrList() : ""}
                  </div>
                </div>
                <div className="SpravaOdpovedi-renderSidebar">
                  {this.renderSidebar()}
                </div>
                <SpravaOdpovediNewDialogCreate
                  project={this.state.project}
                  accessToken={this.props.accessToken}
                  userRole={this.props.userRole}
                  switchDialogNewCreateState={this.switchDialogNewCreateState}
                />
              </div>
            </div>
          );
        }
      }
    }
  }
}

export default SpravaOdpovedi;
