import React from "react";
import "./UserManagement.scss";
import UserManagementList from "./userManagementList/UserManagementList"
import UserManagementEmailNotifications from "./userManagementEmailNotifications/UserManagementEmailNotifications"


class UserManagement extends React.Component {
  state = {};

  render() {
    return (

      <div className="Options container">
      <div className="row">
        <div className="col">
          <h4>User management</h4>
          <UserManagementList accessToken={this.props.accessToken}/>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>User management</h4>
          <UserManagementEmailNotifications accessToken={this.props.accessToken}/>
        </div>
      </div>
    </div>
    );
  }
}

export default UserManagement;
