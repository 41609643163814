import React from "react";
import TalkingCamel from "../../../api/TalkingCamel";
import "./SpravaOdpovediNewDialog.scss";
import RefreshToken from "../../../api/RefreshToken";
import ReactDOM from "react-dom";
import Popup from "../../../components/popup/Popup";
import SpravaOdpovediNewDialogList from "./spravaOdpovediNewDialogList/SpravaOdpovediNewDialogList";
import Loading from "../../loading/Loading";

class SpravaOdpovediNewDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount = () => {
    this.getSODialogNewPreview();
  };

  componentDidUpdate = () => {};

  getSODialogNewPreview = () => {
    TalkingCamel.get(
      "/api/dialog-texts/v2/" + this.props.project + "/dialog-new",
      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        //console.log(response);
        RefreshToken();
        this.setState({
          dialogNewShort: response.data,
          loading: false,
          projectPrev: this.state.project,
          totalCount: response.data.length,
        });
      })
      .catch((error) => {
        console.log(error);
        let errorMessage = "";
        if (error) {
          if (error.response) {
            if (error.response.data) {
              if (error.response.data[0]) {
                if (error.response.data[0].error) {
                  errorMessage = error.response.data[0].error;
                } else {
                  errorMessage = "Unknown Error SpravaOdpovedi";
                }
              } else {
                errorMessage = "Unknown Error SpravaOdpovedi";
              }
            } else {
              errorMessage = "Unknown Error SpravaOdpovedi";
            }
          } else {
            errorMessage = "Unknown Error SpravaOdpovedi";
          }
        } else {
          errorMessage = "Unknown Error SpravaOdpovedi";
        }
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="SpravaOdpovediNewDialog-wrap">
          <div className="SpravaOdpovediNewDialog-renderPrehled">
            <div className="">
              <div className="container">
                <div className="row">
                  <div className="col">Title</div>
                  <div className="col">Created At</div>
                  <div className="col">Accepted</div>
                  <div className="col">{"Current dialogues: "}</div>
                </div>
              </div>
              <Loading />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="SpravaOdpovediNewDialog-wrap">
          <div className="SpravaOdpovediNewDialog-renderPrehled">
            <div className="">
              <div className="container SpravaOdpovediNewDialogListRow">
                <div className="row">
                  <div className="col">Title</div>
                  <div className="col">Created At</div>
                  <div className="col">Accepted</div>
                  <div className="col">{"Current dialogues: " + this.state.totalCount}</div>
                </div>
              </div>
              <SpravaOdpovediNewDialogList
                userRole={this.props.userRole}
                project={this.props.project}
                accessToken={this.props.accessToken}
                searchTerm={this.state.searchTerm}
                dialogNewShort={this.state.dialogNewShort}
                getSODialogNewPreview={this.getSODialogNewPreview}
                username={this.props.username}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default SpravaOdpovediNewDialog;
