import React from "react";
import TalkingCamel from "../../../api/TalkingCamel";
import { Animated } from "react-animated-css";
import ReactDOM from "react-dom";
import Popup from "../../popup/Popup";
import Loading from "../../loading/Loading";

import { stateToHTML } from "draft-js-export-html";
//import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { convertFromRaw } from "draft-js";
//import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import { DataUsageRounded } from "@material-ui/icons";
import Button from "@material-ui/core/Button";

class SpravaOdpovediHistorie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHistory: false,
      loading: false,
      historyDetail: [],
    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  predicateBy = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  componentDidMount = () => {
    this.getHistory();
  };

  componentDidUpdate = () => {};

  getHistory = () => {
    TalkingCamel.get(
      "/api/dialog-texts/v2/" +
        this.props.project +
        "/" +
        this.props.dialogShortId +
        "/history",
      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        this.setState({
          historyDetail: response.data,
          show: true,
          loading: false,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        if (error) {
          if (error.response) {
            if (error.response.data) {
              if (error.response.data[0]) {
                if (error.response.data[0].error) {
                  errorMessage = error.response.data[0].error;
                } else {
                  errorMessage = "Unknown Error SpravaOdpovedi History";
                }
              } else {
                errorMessage = "Unknown Error SpravaOdpovedi History";
              }
            } else {
              errorMessage = "Unknown Error SpravaOdpovedi History";
            }
          } else {
            errorMessage = "Unknown Error SpravaOdpovedi History";
          }
        } else {
          errorMessage = "Unknown Error SpravaOdpovedi History";
        }
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  parseHistory = () => {
    if (this.state.historyDetail.length) {
      let data = this.state.historyDetail;
      let i = 0;
      const dialogHistoryDetail = data
        .filter((dataf) => {
          var dialogNodeId = this.props.dialogNodeId;
          if (dialogNodeId === dataf.nodeId) {
            return true;
          } else {
            return false;
          }
        })
        .map((data) => {
          i++;
          let i2 = 0;
          const contents = data["origin-dialog"].content.map((data) => {
            i2++;
            if (data.response_type === "IMAGE") {
              return (
                <div
                  key={"i_" + i2}
                  className="dialogDetailImage row dialogDetailMessage"
                >
                  <div className="col">
                    <img alt="" src={data.source} />
                  </div>
                </div>
              );
            } else if (data.response_type === "TEXT") {
              return (
                <div
                  key={"t1_" + i2}
                  className="dialogDetailText row dialogDetailMessage"
                >
                  <div className="col">
                    <p>{data.text}</p>
                  </div>
                  <div className="chatAvatar">
                    <img
                      alt=""
                      className=""
                      src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
                    />
                  </div>
                </div>
              );
            } else if (data.response_type === "OPTION") {
              let i3 = 0;
              const detailOption = data.options.map((data) => {
                i3++;
                return (
                  <div key={"op_" + i3} className="option dialogDetailOption">
                    <p>{data.label}</p>
                  </div>
                );
              });
              if (data.title) {
                return (
                    <div key={"o_" + i2} className="row">
                      <div
                        key={"t2_" + i2}
                        className="dialogDetailText row dialogDetailMessage"
                      >
                        <div className="col">
                          <p>{data.title}</p>
                        </div>
                        <div className="chatAvatar">
                          <img
                            alt=""
                            className=""
                            src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
                          />
                        </div>
                      </div>
                      <div className="dialogDetailOptions row">{detailOption}</div>
                    </div>
                );
              } else {
                return (
                  <div key={"o_" + i2} className="">
                    <div className="">
                      <div className="dialogDetailOptions row">{detailOption}</div>
                    </div>
                  </div>
                );
              }
            }
            return "";
          });
          let datac = data.comments.sort(this.predicateBy("commented-at"));
          let datalength = datac.length;
          let i4 = 0;
          const dialogComments = datac.map((data) => {
            i4++;
            let timestamp = this.getDateTime(Date.parse(data["commented-at"]));
            let author = data.author;
            if (datalength === i && this.props.username === data.author) {
              return (
                <Animated
                  key={"dialogCommentsa" + i4}
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  animationInDuration={1000}
                  animationOutDuration={1000}
                  isVisible={this.state.showDetail}
                >
                  <div
                    key={"dialogCommentsb" + i4}
                    className="row comment commentLast commentedByMe"
                  >
                    <div className="col-1 commentAuthor">
                      {this.renderAvatar("ByMe", author)}
                    </div>
                    {this.convertCommentFromJSONToHTML(
                      data.text,
                      timestamp,
                      author
                    )}
                  </div>
                  <div className="col-1"></div>
                </Animated>
              );
            } else if (this.props.username !== data.author) {
              return (
                <div key={"dialogCommentsc" + i4} className="row comment">
                  <div className="col-1"></div>
                  {this.convertCommentFromJSONToHTML(
                    data.text,
                    timestamp,
                    author
                  )}
                  <div className="col-1 commentAuthor">
                    {this.renderAvatar("NotMe", author)}
                  </div>
                  <div className="col-1"></div>
                </div>
              );
            } else {
              return (
                <div
                  key={"dialogCommentsd" + i4}
                  className="row comment commentedByMe"
                >
                  <div className="col-1 commentAuthor ">
                    {this.renderAvatar("ByMe", author)}
                  </div>
                  {this.convertCommentFromJSONToHTML(
                    data.text,
                    timestamp,
                    author
                  )}
                  <div className="col-1"></div>
                  <div className="col-1"></div>
                </div>
              );
            }
          });
          return (
            <div className="resolved-comments" key={"requestResolved" + i}>
              <div className="container">
                <div className="row  requestResolvedRow">
                  <div className="col">
                    <b>{"Status: " + data.status}</b>
                  </div>
                  <div className="col">
                    {"Requested at: " +
                      this.getDateTime(Date.parse(data["requested-at"]))}
                  </div>
                  <div className="col">
                    {"Resolved at: " +
                      this.getDateTime(Date.parse(data["resolved-at"]))}
                  </div>
                  <div className="col">
                    <b>{"For version: " + data["version"]}</b>
                  </div>
                </div>
              </div>
              <div className="container">{contents}</div>
              <div key={"dialogComments" + i} className="container">
                {data.comments ? dialogComments : "No comments"}
              </div>
            </div>
          );
          //return contents;
        });
      return dialogHistoryDetail;
    } else {
      return (
        <div className="no-history">
          No results for this dialog and platform. Go out there and make History! :)
        </div>
      )
    }
  };

  renderAvatar = (user, author) => {
    //let avatarImg = "";
    if (author.includes("@addai.cz") || author === "admin") {
      return (
        <div className="chatAvatar">
          <img
            alt=""
            className=""
            src="https://addai.life/wp-content/uploads/2020/05/Webchat-icon.png"
          />
        </div>
      );
    } else {
      return (
        <div className="chatAvatar noOutline">
          <img alt="" className="" src="profile.png" />
        </div>
      );
    }
  };

  parseResponseKomentar = () => {
    if (this.props.comments) {
      let data = this.props.comments.sort(this.predicateBy("commented-at"));
      let datalength = data.length;
      let i = 0;
      const dialogComments = data.map((data) => {
        i++;
        let timestamp = this.getDateTime(Date.parse(data["commented-at"]));
        let author = data.author;
        if (datalength === i && this.props.username === data.author) {
          return (
            <Animated
              key={"dialogCommentsa" + i}
              animationIn="fadeIn"
              animationOut="fadeOut"
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={this.state.showDetail}
            >
              <div
                key={"dialogCommentsb" + i}
                className="row comment commentLast commentedByMe"
              >
                <div className="col-1 commentAuthor">
                  {this.renderAvatar("ByMe", author)}
                </div>
                {this.convertCommentFromJSONToHTML(
                  data.text,
                  timestamp,
                  author
                )}
              </div>
              <div className="col-1"></div>
            </Animated>
          );
        } else if (this.props.username !== data.author) {
          return (
            <div key={"dialogCommentsc" + i} className="row comment">
              <div className="col-1"></div>
              {this.convertCommentFromJSONToHTML(data.text, timestamp, author)}
              <div className="col-1 commentAuthor">
                {this.renderAvatar("NotMe", author)}
              </div>
              <div className="col-1"></div>
            </div>
          );
        } else {
          return (
            <div
              key={"dialogCommentsd" + i}
              className="row comment commentedByMe"
            >
              <div className="col-1 commentAuthor ">
                {this.renderAvatar("ByMe", author)}
              </div>
              {this.convertCommentFromJSONToHTML(data.text, timestamp, author)}
              <div className="col-1"></div>
              <div className="col-1"></div>
            </div>
          );
        }
      });
      return (
        <div key={"dialogComments" + i} className="container">
          {dialogComments}
        </div>
      );
    } else return <div>No comments</div>;
  };

  toggleHistory = () => {
    this.setState((prevState) => ({
      showHistory: prevState.showHistory === true ? false : true,
    }));
  };

  convertCommentFromJSONToHTML = (text, timestamp, author) => {
    if (this.isJson(text)) {
      let parsedText = {
        __html: stateToHTML(convertFromRaw(JSON.parse(text))),
      };
      return (
        <div className="col-10 messageBubble">
          <div
            className="commentText"
            dangerouslySetInnerHTML={parsedText}
          ></div>
          <div className="messageTime">{timestamp}</div>
          <div className="messageAuthor">{author}</div>
        </div>
      );
    } else {
      return (
        <div className="col-10 messageBubble">
          <div className="commentText">{text}</div>
          <div className="messageTime">{timestamp}</div>
          <div className="messageAuthor">{author}</div>
        </div>
      );
    }
  };

  isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  getDateTime = (data) => {
    if (data) {
      data = data + 2 * 60 * 60 * 1000;
      return new Intl.DateTimeFormat("en-GB", {
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }).format(data);
    } else return "Error on time parsing";
  };

  renderHistoryShowButton = () => {
    return (
      <div className="">
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={this.toggleHistory}
        >
          {!this.state.showHistory ? "Show History" : "Hide History"}
        </Button>
      </div>
    );
  };

  render() {
    if (!this.state.showHistory) {
      return (
        <div className="SpravaOdpovediHistory container">
          {this.renderHistoryShowButton()}
        </div>
      );
    } else {
      if (this.state.loading) {
        return (
          <div className="SpravaOdpovediHistory container">
            {this.renderHistoryShowButton()}
            <Loading />
          </div>
        );
      } else {
        return (
          <div className="SpravaOdpovediHistory container">
            {this.renderHistoryShowButton()}
            {this.parseHistory()}
          </div>
        );
      }
    }
  }
}
export default SpravaOdpovediHistorie;
