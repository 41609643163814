import "./UserManagementRoles.scss";
import React from "react";
import TalkingCamel from "../../../api/TalkingCamel";
import ReactDOM from "react-dom";
import Popup from "../../../components/popup/Popup";

class UserManagementEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderProjects = (type) => {
    let projects = "";
    window.localStorage.getItem("data")
      ? (projects = JSON.parse(window.localStorage.getItem("data")).projects)
      : (projects = "");
    let i = 0;
    if (window.localStorage.getItem("data")) {
      const projectsList = projects.map((projects) => {
        i++;
        if (type === "SO") {
          return (
            <option key={"option_" + i} value={projects.id}>
              {projects.name}
            </option>
          );
        } else if (type === "TT") {
          return (
            <option key={"option_" + i} value={projects.name}>
              {projects.name}
            </option>
          );
        }
      });
      if (type === "SO") {
        return (
          <select id="userManagementRolesProjectSO" className="form-control">
            {projectsList}
          </select>
        );
      }
      if (type === "TT") {
        return (
          <select id="userManagementRolesProjectTT" className="form-control">
            {projectsList}
          </select>
        );
      }
    }
    return "";
  };

  renderRoles = () => {
    let data = this.props.roles;
    let i = 0;
    let userRoles = "";

    if (data) {
      userRoles = data.map((roles) => {
        i++;
        return (
          <div className="row" key={i}>
            <div className="col">{roles}</div>
            <div className="col-2">
              <button
                onClick={(e) => this.removeRole(roles)}
                type="button"
                className="btn btn-danger"
              >
                X
              </button>
            </div>
          </div>
        );
      });
    }
    return (
      <div className="container">
        <h6 className="row">Delete user role</h6>
        {userRoles}
      </div>
    );
  };

  addRole = (type) => {
    let role;

    if (type === "SO") {
      role =
        document.getElementById("userManagementRolesRoleSO").value +
        ":" +
        document.getElementById("userManagementRolesProjectSO").value;
    } else if (type === "TT") {
      role =
        document.getElementById("userManagementRolesRoleTT").value +
        ":" +
        document.getElementById("userManagementRolesProjectTT").value;
    }

    TalkingCamel.post(
      "/api/v1/users/" + this.props.username + "/role",
      {
        role: role,
      },
      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        this.props.updateUserList();
      })
      .catch((error) => {
        let errorMessage = "";
        error.response.data[0].error
          ? (errorMessage = "Unknown Error UserManagementCreateUser addRole")
          : (errorMessage = error.response.data[0].error);
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  removeRole = (role) => {
    console.log(role);
    console.log(this.props.username);
    TalkingCamel.delete(
      "/api/v1/users/" + this.props.username + "/role/" + role,

      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        this.props.updateUserList();
      })
      .catch((error) => {
        let errorMessage = "";
        !error.response.data[0].error
          ? (errorMessage = "Unknown Error UserManagementCreateUser addRole")
          : (errorMessage = error.response.data[0].error);
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  render() {
    return (
      <div className="userManagementRoles ">
        <h6>SO Add role</h6>
        <select id="userManagementRolesRoleSO" className="form-control ">
          <option value="EDITOR">Editor</option>
          <option value="CUSTOMER_EDITOR">Customer editor</option>
          <option value="CUSTOMER_READER">Customer reader</option>
        </select>
        {this.renderProjects("SO")}
        <button
          onClick={(e) => {
            this.addRole("SO");
          }}
          type="button"
          className="btn btn-success"
        >
          Add role
        </button>
        <h6>TT new role</h6>
        <select id="userManagementRolesRoleTT" className="form-control ">
          <option value="TRAINING_TRAINER">Training trainer</option>
          <option value="TRAINING_BROWSER">Training browser</option>
        </select>
        {this.renderProjects("TT")}
        <button
          onClick={(e) => {
            this.addRole("TT");
          }}
          type="button"
          className="btn btn-success"
        >
          Add role
        </button>
        {this.renderRoles()}
      </div>
    );
  }
}

export default UserManagementEdit;
