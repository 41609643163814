import "./UserManagementEdit.scss";
import React from "react";
import TalkingCamel from "../../../api/TalkingCamel";
import ReactDOM from "react-dom";
import Popup from "../../../components/popup/Popup";
import UserManagementRoles from "../userManagementRoles/UserManagementRoles";

class UserManagementEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      firstName: "",
      lastName: "",
      email: "",
    };
  }

  onSubmitDeleteUser = (e) => {
    e.preventDefault();
    TalkingCamel.delete("/api/v1/users/" + this.props.username, {
      headers: {
        Authorization: "Bearer " + this.props.accessToken,
      },
    })
      .then((response) => {
        this.props.updateUserList();
      })
      .catch((error) => {
        let errorMessage = "";
        !error.response.data[0].error
          ? (errorMessage =
              "Unknown Error UserManagementEdit onSubmitDeleteUser")
          : (errorMessage = error.response.data[0].error);
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };
  onSubmitEditUser = (e) => {
    let firstname = this.state.firstName
      ? this.state.firstName
      : this.props.firstname;
    let lastname = this.state.lastName
      ? this.state.lastName
      : this.props.lastname;
    let email = this.state.email ? this.state.email : this.props.email;
    e.preventDefault();
    TalkingCamel.put(
      "/api/v1/users/" + this.props.username,
      {
        firstname,
        lastname,
        email,
      },
      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        this.props.updateUserList();
      })
      .catch((error) => {
        let errorMessage = "";
        !error.response.data[0].error
          ? (errorMessage = "Unknown Error UserManagementEdit onSubmitEditUser")
          : (errorMessage = error.response.data[0].error);
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  onSubmitChangePassword = (e) => {
    e.preventDefault();
    TalkingCamel.put(
      "/api/v1/users/" + this.props.username + "/password",
      {
        "new-password": this.state.password,
      },
      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        ReactDOM.render(
          <Popup type="correct" text="passwordChangeSuccess" />,
          document.getElementById("popup-window")
        );
      })
      .catch((error) => {
        let errorMessage = "";
        !error.response.data[0].error
          ? (errorMessage =
              "Unknown Error UserManagementEdit onSubmitChangePassword")
          : (errorMessage = error.response.data[0].error);
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  showContent = () => {
    if (this.props.showContent === "deleteUser") {
      return (
        <div>
          <h5>deleteUser</h5>
          <form onSubmit={this.onSubmitDeleteUser}>
            <button type="submit" className={"btn btn-primary deleteUser"}>
              {" "}
              Delete user
            </button>
          </form>
        </div>
      );
    } else if (this.props.showContent === "editUser") {
      return (
        <div className="editUser container">
          <div className="row ">
            <div className="col">
              <h5>Edit user</h5>
            </div>
          </div>
          <form onSubmit={this.onSubmitEditUser} autoComplete="off">
            <div className="row">
              <div className="col-2">First Name: </div>
              <div className="col-4">
                <input
                  type="text"
                  placeholder="First Name"
                  className="form-control"
                  value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                />
              </div>
              <div className="col-2">Last Name: </div>
              <div className="col-4">
                <input
                  type="text"
                  placeholder="Last Name"
                  className="form-control"
                  value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-2">Email: </div>
              <div className="col-4">
                <input
                  type="text"
                  placeholder="something@that.it"
                  className="form-control"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3"></div>
              <div className="col-6">
                <button type="submit" className={"btn btn-primary "}>
                  {" "}
                  Submit Changes
                </button>
              </div>
              <div className="col-3"></div>
            </div>
          </form>

          <form onSubmit={this.onSubmitChangePassword} autoComplete="off">
            <div className="row">
              <div className="col-2">New password: </div>
              <div className="col-4">
                <input
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <div className="col-6">
                <button type="submit" className={"btn btn-primary "}>
                  {" "}
                  Submit Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else if (this.props.showContent === "changeRole") {
      return (
        <div>
          <h5>changeRole</h5>
          <UserManagementRoles
            updateUserList={this.props.updateUserList}
            roles={this.props.roles}
            username={this.props.username}
            accessToken={this.props.accessToken}
          />
        </div>
      );
    }
  };

  render() {
    return (
      <div className="UserManagementEdit-container">
        <div className="UserManagementEdit">
          <div>
            <h5>User Management Edit</h5>
          </div>
          {this.showContent()}
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.updateUserList}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    );
  }
}

export default UserManagementEdit;
