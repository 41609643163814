import ReactDOM from "react-dom";
import React from "react";
import TalkingCamel from "../../api/TalkingCamel";
import "./Login.scss";
import Popup from "../popup/Popup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    userRole: "",
    showPassword: false,
    error: false,
  };

  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: prevState.showPassword === true ? false : true,
    }));
  };

  onInputSubmit = (e) => {
    e.preventDefault();
    TalkingCamel.post("/api/auth/login", {
      username: this.state.username,
      password: this.state.password,
      clientID: "CURIOUS_CHICKEN",
    })
      .then((response) => {
        if (!response.data.user.roles) {
          let errorMessage = "Error no role assigned";
          ReactDOM.render(
            <Popup type="error" text={errorMessage} />,
            document.getElementById("popup-window")
          );
        } else {
          //console.log(response.data);
          window.localStorage.setItem("data", JSON.stringify(response.data));
          window.localStorage.setItem(
            "accessToken",
            response.data.tokens["access-token"]
          );
          window.localStorage.setItem(
            "refreshToken",
            response.data.tokens["refresh-token"]
          );
          this.props.loginState(
            response.data.user.username,
            response.data.tokens["access-token"],
            response.data.tokens["refresh-token"],
            response.data.user.roles,
            response.data.projects
          );
        }
      })
      .catch((error) => {
        this.setState({ error: true });
      });
  };

  render() {
    return (
      <div className="Login-wrap container">
        <form onSubmit={this.onInputSubmit}>
          <div className="Login-username-wrap row">
            <TextField
              error={this.state.error ? true : false}
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              onChange={(e) => this.setState({ username: e.target.value })}
              color="primary"
            />
          </div>
          <div className="Login-password-wrap row">
            <TextField
              error={this.state.error ? true : false}
              helperText={this.state.error ? "Username or password is incorrect" : ""}
              color="primary"
              variant="outlined"
              margin="normal"
              fullWidth
              label="Password"
              id="password"
              autoComplete="current-password"
              type={this.state.showPassword ? "text" : "password"}
              onChange={(e) => this.setState({ password: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}


            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;