import React from "react";
import "./UserManagementList.scss";
import TalkingCamel from "../../../api/TalkingCamel";
import RefreshToken from "../../../api/RefreshToken";
import ReactDOM from "react-dom";
import Popup from "../../../components/popup/Popup";
import UserManagementEdit from "../userManagementEdit/UserManagementEdit";
import UserManagementCreateUser from "../userManagementCreateUser/UserManagementCreateUser";

class UserManagementList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      showContent: "",
      show: "",
      username: "",
      roles: [],
      usersLoaded: false,
    };
  }

  componentDidMount = () => {
    this.getUsersList();
    RefreshToken();
  };

  getUsersList = () => {
    TalkingCamel.get("/api/v1/users", {
      headers: {
        Authorization: "Bearer " + this.props.accessToken,
      },
    })
      .then((response) => {
        this.setState({ users: response.data });
      })
      .catch((error) => {
        let errorMessage = "";
        !error.response.data[0].error
          ? (errorMessage = "Unknown Error UserManagementList")
          : (errorMessage = error.response.data[0].error);
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  parseUsers = () => {
    //const data = JSON.stringify();
    let i = 0;
    let i2 = 0;
    const userList = this.state.users.map((data2) => {
      i++;
      let userRoles = "";
      if (data2.roles) {
        userRoles = data2.roles.map((roles2) => {
          i2++;
          return <div key={i2}>{roles2}</div>;
        });
      }
      return (
        <div key={i} className="container userLine">
          <div className="row">
            <div className="col">{data2.username}</div>
            <div className="col">{data2.email ? data2.email : "N/A"}</div>
            <div className="col">
              {(data2.firstname ? data2.firstname : "N/A") +
                " " +
                (data2.lastname ? data2.lastname : "N/A")}
            </div>
            <div className="col">{userRoles ? userRoles : ""}</div>
            {this.renderOperationsBlock(data2)}
          </div>
        </div>
      );
    });
    return userList;
  };

  renderOperationsBlock = (data) => {
    if (data.username !== "admin") {
      return (
        <div className="col">
          <button
            onClick={(e) =>
              this.setState({
                showContent: "changeRole",
                show: true,
                username: e.target.value,
              })
            }
            value={data.username}
            type="button"
            className="btn btn-success"
          >
            +
          </button>
          <button
            onClick={(e) =>
              this.setState({
                showContent: "editUser",
                show: true,
                username: e.target.value,
              })
            }
            value={data.username}
            type="button"
            className="btn"
          >
            /
          </button>
          <button
            onClick={(e) =>
              this.setState({
                showContent: "deleteUser",
                show: true,
                username: e.target.value,
              })
            }
            value={data.username}
            type="button"
            className="btn btn-danger"
          >
            X
          </button>
          {this.state.show && this.state.username === data.username ? (
            <UserManagementEdit
              showContent={this.state.showContent}
              accessToken={this.props.accessToken}
              username={this.state.username}
              //updateList={this.updateList}
              firstname={data.firstname}
              lastname={data.lastname}
              email={data.email}
              roles={data.roles}
              updateUserList={this.updateUserList}
            />
          ) : (
            ""
          )}
        </div>
      );
    } else return <div className="col"></div>;
  };

  updateUserList = () => {
    this.getUsersList();
    this.setState({ show: false });
  };

  render() {
    return (
      <div className="UserManagementList container">
        <div className="row">
          <div className="col">
            <h5>User list</h5>
          </div>
        </div>
        <div className="row">
          <div className="col">Username</div>
          <div className="col">Email</div>
          <div className="col">Name</div>
          <div className="col">Role</div>
          <div className="col">Operations</div>
        </div>
        {this.parseUsers()}
        <UserManagementCreateUser accessToken={this.props.accessToken} updateUserList={this.updateUserList}/>
      </div>
    );
  }
}

export default UserManagementList;
