import React from "react";
import jwt from "jwt-decode";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import "./Mobile.scss";
import Login from "./components/login/Login";
import UserProfile from "./components/userProfile/UserProfile";
import SpravaOdpovedi from "./components/spravaOdpovedi/SpravaOdpovedi";
import DataImport from "./components/dataImport/DataImport";
import ProjectSelect from "./components/projectSelect/ProjectSelect";
import UserManagement from "./components/userManagement/UserManagement";

import { withStyles } from "@material-ui/core/styles";
import Dashboard from "./components/dashboard/Dashboard";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";

//import Testblock from "./components/Testblock"

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

/*
<div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>xs=12</Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>xs=6</Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>xs=6</Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper}>xs=3</Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper}>xs=3</Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper}>xs=3</Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper className={classes.paper}>xs=3</Paper>
              </Grid>
            </Grid>
          </div>
*/

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      loggedIn: false,
      username: "",
      accessToken: "",
      refreshToken: "",
      project: "",
      projects: "",
      projectName: "",
      showContent: "SpravaOdpovedi",
      userRole: [],
      heightMenu: 0,
      showMobile: false,
      screenWidth: 0,
      showMenu: false,
    };
  }

  componentDidMount = () => {
    this.setState({ screenWidth: window.innerWidth });
    if (window.localStorage.getItem("refreshToken")) {
      if (this.state.userRole.length === 0) {
        this.setRolesArr();
      }

      if (!this.state.project) {
        let projectsLocal = JSON.parse(window.localStorage.getItem("data"))
          .projects[0].id;
        this.setState({ project: projectsLocal });
      }

      if (!this.state.projectName) {
        let projectsLocal = JSON.parse(window.localStorage.getItem("data"))
          .projects[0].name;
        this.setState({ projectName: projectsLocal });
      }

      if (!this.state.username) {
        let usernameLocal = JSON.parse(window.localStorage.getItem("data")).user
          .username;
        this.setState({ username: usernameLocal });
      }
      if (!this.state.user) {
        let userLocal = JSON.parse(window.localStorage.getItem("data")).user;
        this.setState({ user: userLocal });
      }
      if (this.decode()) {
        let accessTokenLocal = window.localStorage.getItem("accessToken");
        let refreshTokenLocal = window.localStorage.getItem("refreshToken");
        this.setState({
          loggedIn: true,
          accessToken: accessTokenLocal,
          refreshToken: refreshTokenLocal,
        });
      } else {
        this.setState(this.initialState);
        clearInterval(this.interval);
      }
    }
  };

  componentDidUpdate = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.state.loggedIn) {
      this.interval = setInterval(() => this.periodicLoginCheck(), 10000);
    }
    if (window.localStorage.getItem("refreshToken")) {
      if (this.state.userRole.length === 0) {
        this.setRolesArr();
      }

      if (!this.state.project) {
        let projectsLocal = JSON.parse(window.localStorage.getItem("data"))
          .projects[0].id;
        this.setState({ project: projectsLocal });
      }

      if (!this.state.projectName) {
        let projectsLocal = JSON.parse(window.localStorage.getItem("data"))
          .projects[0].name;
        this.setState({ projectName: projectsLocal });
      }

      if (!this.state.username) {
        let usernameLocal = JSON.parse(window.localStorage.getItem("data")).user
          .username;
        this.setState({ username: usernameLocal });
      }
      if (!this.state.user) {
        let userLocal = JSON.parse(window.localStorage.getItem("data")).user;
        this.setState({ user: userLocal });
      }
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  setRolesArr = () => {
    let userRoleLocal = JSON.parse(window.localStorage.getItem("data")).user
      .roles;
    this.setState({ userRole: userRoleLocal });
    //console.log(userRoleLocal);
  };

  LoginState = (username, accessToken, refreshToken, userRole, projects) => {
    this.setState({
      loggedIn: true,
      username: username,
      accessToken: accessToken,
      refreshToken: refreshToken,
      userRole: userRole,
      projects: projects,
    });
  };

  switchLoggedInState = () => {
    clearInterval(this.interval);
    this.setState(this.initialState);
    window.localStorage.removeItem("data");
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");
  };

  decode = () => {
    let token = jwt(window.localStorage.getItem("accessToken"));
    if (parseInt(token.exp) > Math.ceil(parseInt(Date.now()) / 1000)) {
      return true;
    } else {
      return false;
    }
  };

  periodicLoginCheck = () => {
    let token = jwt(window.localStorage.getItem("accessToken"));
    if (parseInt(token.exp) < Math.ceil(parseInt(Date.now()) / 1000) - 3600) {
      this.switchLoggedInState();
    }
  };

  onProjectSelect = (selectedProject, valueName) => {
    this.setState({ project: selectedProject, projectName: valueName });
  };

  changeToMobileMenu = () => {
    document.getElementById("top-menu-right").classList.add("mobile");
    this.setState({ showMobile: true });
  };

  changeToStandartMenu = () => {
    document.getElementById("top-menu-right").classList.remove("mobile");
    this.setState({ showMobile: false });
  };
  recordButtonPosition = (event) => {
    this.setState({ anchorEl: event.currentTarget, showMenu: true });
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      showMenu: prevState.showMenu === true ? false : true,
    }));
  };

  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      this.handleToggle();
    }
  };

  renderAvatar = () => {
    if (this.state.user) {
      return (
        <span
          className="main-title-user-avatar"
          onClick={this.recordButtonPosition}
        >
          <span className="main-title-user-avatar-first">
            {this.state.user.firstname
              ? this.state.user.firstname.slice(0, 1)
              : "A"}
          </span>
          <span className="main-title-user-avatar-last">
            {this.state.user.lastname
              ? this.state.user.lastname.slice(0, 1)
              : "D"}
          </span>
        </span>
      );
    } else {
      return (
        <span
          className="main-title-user-avatar"
          onClick={this.recordButtonPosition}
        >
          <span className="main-title-user-avatar-first"></span>
          <span className="main-title-user-avatar-last"></span>
        </span>
      );
    }
  };
  /*
<MenuItem
              onClick={(e) => {
                this.setState(
                  {
                    showContent: "Dashboard",
                  },
                  () => {
                    this.handleToggle();
                  }
                );
              }}
            >
              Dashboard
            </MenuItem>
*/
  renderMenu = () => {
    return (
      <div className="menu">
        <div>
          <ClickAwayListener
            onClickAway={() => {
              this.setState({ showMenu: false });
            }}
          >
            {this.renderAvatar()}
          </ClickAwayListener>
          <Menu
            id="menu-list-grow"
            onKeyDown={this.handleListKeyDown}
            transitioncomponent={Fade}
            open={this.state.showMenu}
            anchorEl={this.state.anchorEl}
          >
            <MenuItem
              onClick={(e) => {
                this.setState(
                  {
                    showContent: "SpravaOdpovedi",
                  },
                  () => {
                    this.handleToggle();
                  }
                );
              }}
            >
              Dialog management
            </MenuItem>
            {this.state.userRole.includes("ADMIN") ||
            this.state.userRole.includes("EDITOR:" + this.state.project) ? (
              <MenuItem
                onClick={(e) => {
                  this.setState(
                    {
                      showContent: "Import",
                    },
                    () => {
                      this.handleToggle();
                    }
                  );
                }}
              >
                Data Import
              </MenuItem>
            ) : (
              ""
            )}
            {this.state.userRole.includes("ADMIN") ? (
              <MenuItem
                onClick={(e) => {
                  this.setState(
                    {
                      showContent: "UserManagement",
                    },
                    () => {
                      this.handleToggle();
                    }
                  );
                }}
              >
                User Management
              </MenuItem>
            ) : (
              ""
            )}
            <MenuItem
              onClick={(e) => {
                this.setState(
                  {
                    showContent: "UserProfile",
                  },
                  () => {
                    this.handleToggle();
                  }
                );
              }}
            >
              My account
            </MenuItem>
            <MenuItem onClick={this.switchLoggedInState}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
    );
  };

  render() {
    if (!this.state.loggedIn) {
      return (
        <div
          className={"App " + (this.state.loggedIn ? "loggedIn" : "loggeOut")}
        >
          <div className="App-header">
            <div className="main-title-logo">
              <img
                alt="Main Logo"
                id="logo-header"
                src="/Logo-transparent.png"
              />
            </div>
            <div className="main-title">
              <h1>Assistant Administration</h1>
            </div>
            <div className="main-title-empty">.</div>
          </div>
          <div className="container">
            <div className="App-body">
              <Login loginState={this.LoginState} />
            </div>
          </div>
          <div className="App-footer">
            <div className="col">Made by AddAI team</div>
          </div>
        </div>
      );
    }
    if (this.state.loggedIn) {
      return (
        <div
          className={"App " + (this.state.loggedIn ? "loggedIn" : "loggeOut")}
        >
          <div className="App-header">
            <div className="main-title-logo">
              <img
                alt="Main Logo"
                id="logo-header"
                src="/Logo-transparent.png"
              />
            </div>
            <div className="main-title">
              <h1>Assistant Administration</h1>
            </div>
            <div className="top-menu-right" id="top-menu-right">
              {this.state.userRole[1] ||
              this.state.userRole.includes("ADMIN") ? (
                <ProjectSelect onProjectSelect={this.onProjectSelect} />
              ) : (
                ""
              )}
              {this.renderMenu()}
            </div>
          </div>
          <div className="container">
            <div className="App-body">
              {this.state.showContent === "Import" ? (
                <DataImport
                  project={this.state.project}
                  userRole={this.state.userRole}
                  accessToken={this.state.accessToken}
                />
              ) : (
                ""
              )}
              {this.state.showContent === "SpravaOdpovedi" &&
              this.state.project ? (
                <SpravaOdpovedi
                  userRole={this.state.userRole}
                  project={this.state.project}
                  accessToken={this.state.accessToken}
                  username={this.state.username}
                  projectName={this.state.projectName}
                />
              ) : (
                ""
              )}
              {this.state.showContent === "UserManagement" ? (
                <UserManagement
                  username={this.state.username}
                  accessToken={this.state.accessToken}
                />
              ) : (
                ""
              )}
              {this.state.showContent === "UserProfile" ? (
                <UserProfile
                  username={this.state.username}
                  accessToken={this.state.accessToken}
                />
              ) : (
                ""
              )}
              {/*this.state.showContent === "TrainingTool" ? (
                <TrainingTool
                  username={this.state.username}
                  accessToken={this.state.accessToken}
                  project={this.state.project}
                />
              ) : (
                ""
              )*/}
              {/*this.state.showContent === "Dashboard" && this.state.project ? (
                <Dashboard
                  userRole={this.state.userRole}
                  project={this.state.project}
                  accessToken={this.state.accessToken}
                />
              ) : (
                ""
              )*/}
            </div>
          </div>
          <div className="App-footer">
            <div className="col">Made by AddAI team</div>
          </div>
        </div>
      );
    }
  }
}

export default withStyles(useStyles)(App);
