import React from "react";
import "./UserManagementCreateUser.scss";
import TalkingCamel from "../../../api/TalkingCamel";
import ReactDOM from "react-dom";
import Popup from "../../../components/popup/Popup";

class UserManagementCreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    };
  }

  createUser = (e) => {
    e.preventDefault();
    if (
      !this.state.email ||
      !this.state.username ||
      !this.state.password
    ) {
      ReactDOM.render(
        <Popup type="error" text="fillCreateUserForm" />,
        document.getElementById("popup-window")
      );
    } else {
      TalkingCamel.post(
        "/api/v1/users",
        {
          username: this.state.username,
          password: this.state.password,
          firstname: this.state.firstName,
          lastname: this.state.lastName,
          email: this.state.email,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.accessToken,
          },
        }
      )
        .then((response) => {
          ReactDOM.render(
            <Popup type="success" text={"userCreated"} />,
            document.getElementById("popup-window")
          );
          this.props.updateUserList();
        })
        .catch((error) => {
          let errorMessage = "";
          !error.response.data[0].error
            ? (errorMessage =
                "Unknown Error UserManagementCreateUser createUser")
            : (errorMessage = error.response.data[0].error);
          ReactDOM.render(
            <Popup type="error" text={errorMessage} />,
            document.getElementById("popup-window")
          );
        });
    }
  };

  render() {
    return (
      <div className="createUser container">
        <div className="row">
          <div className="col">
            <h5>Create User</h5>
          </div>
        </div>
        <form onSubmit={this.createUser} autoComplete="off">
          <div className="row">
            <div className="col-2">Username: </div>
            <div className="col-4">
              <input
                type="text"
                placeholder="Username"
                className="form-control"
                value={this.state.term}
                onChange={(e) => this.setState({ username: e.target.value })}
              />
            </div>
            <div className="col-2">Password: </div>
            <div className="col-4">
              <input
                autoComplete="new-password"
                type="password"
                placeholder="Password"
                className="form-control"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2">First Name: </div>
            <div className="col-4">
              <input
                type="text"
                placeholder="First Name"
                className="form-control"
                value={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
              />
            </div>
            <div className="col-2">Last Name: </div>
            <div className="col-4">
              <input
                type="text"
                placeholder="Last Name"
                className="form-control"
                value={this.state.lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2">Email: </div>
            <div className="col-4">
              <input
                type="text"
                placeholder="something@that.it"
                className="form-control"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-6">
              <button type="submit" className={"btn btn-primary "}>
                {" "}
                Add User
              </button>
            </div>
            <div className="col-3"></div>
          </div>
        </form>
      </div>
    );
  }
}

export default UserManagementCreateUser;
