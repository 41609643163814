import React from "react";
import TalkingCamel from "../../api/TalkingCamel";
import "./UserProfile.scss";
import ReactDOM from "react-dom";
import Popup from "../popup/Popup";
import RefreshToken from "../../api/RefreshToken";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      role: "",
      showProfile: false,
      input: {},
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    TalkingCamel.get("/api/v1/users/" + this.props.username, {
      headers: {
        Authorization: "Bearer " + this.props.accessToken,
      },
    })
      .then((response) => {
        RefreshToken();
        if (response.data[0]) {
          this.setState({
            id: response.data[0].id,
            role: response.data[0].roles[0],
            showProfile: true,
          });
        } else {
          this.setState({ id: response.data.id, role: response.data.roles[0] });
        }
      })
      .catch((error) => {
        let errorMessage = "";
        !error.response.data[0].error
          ? (errorMessage = "Unknown Error")
          : (errorMessage = error.response.data.message);
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  onSubmitChangePassword = () => {
    TalkingCamel.put(
      "/api/v1/users/password",
      {
        "old-password": this.state.input.old_password,
        "new-password": this.state.input.confirm_password,
      },
      {
        headers: {
          Authorization: "Bearer " + this.props.accessToken,
        },
      }
    )
      .then((response) => {
        ReactDOM.render(
          <Popup type="correct" text="passwordChangeSuccess" />,
          document.getElementById("popup-window")
        );
      })
      .catch((error) => {
        let errorMessage = "";
        !error.response.data[0].error
          ? (errorMessage =
              "Unknown Error UserManagementEdit onSubmitChangePassword")
          : (errorMessage = error.response.data[0].error);
        ReactDOM.render(
          <Popup type="error" text={errorMessage} />,
          document.getElementById("popup-window")
        );
      });
  };

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      let input = {};
      input["old_password"] = "";
      input["password"] = "";
      input["confirm_password"] = "";
      this.setState({ input: input });

      this.onSubmitChangePassword();
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    if (!input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "Please enter your confirm password.";
    }

    if (
      typeof input["password"] !== "undefined" &&
      typeof input["confirm_password"] !== "undefined"
    ) {
      if (input["password"] !== input["confirm_password"]) {
        isValid = false;
        errors["password"] = "Passwords don't match.";
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  render() {
    return (
      <div className="Profile container">
        <div className="row">
          <div className="col">
            <h4>User profile</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-6">Users ID: {this.state.id}</div>
          <div className="col-6">Users role: {this.state.role}</div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row form-group">
            <div className="col-3">Old password:</div>
            <input
              type="password"
              name="old_password"
              value={this.state.input.old_password}
              onChange={this.handleChange}
              className="form-control col-8"
              placeholder="Enter old password"
              id="old_password"
            />

            <div className="text-danger">{this.state.errors.old_password}</div>
          </div>

          <div className=" row form-group">
            <div className="col-3">New password:</div>
            <input
              type="password"
              name="password"
              value={this.state.input.password}
              onChange={this.handleChange}
              className="form-control col-8"
              placeholder="Enter new password"
              id="password"
            />

            <div className="text-danger">{this.state.errors.password}</div>
          </div>

          <div className="row form-group">
            <div className="col-3">Confirm password:</div>
            <input
              type="password"
              name="confirm_password"
              value={this.state.input.confirm_password}
              onChange={this.handleChange}
              className="form-control col-8"
              placeholder="Enter confirm new password"
              id="confirm_password"
            />

            <div className="text-danger">
              {this.state.errors.confirm_password}
            </div>
          </div>

          <input type="submit" value="Submit" className="btn btn-success" />
        </form>
      </div>
    );
  }
}

export default UserProfile;
