import ReactDOM from "react-dom";
import Popup from "../popup/Popup";
import "./DataImport.scss";
import React from "react";
import RefreshToken from "../../api/RefreshToken";
import GetProjectVersion from "../getProjectVersion/GetProjectVersion";

class DataImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      versionProd: "",
      versionDev: "",
      project: this.props.project,
      projectPrev: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      project: nextProps.project,
    };
  }

  componentDidMount = () => {
    this.getProjectVersion();
  };

  componentDidUpdate = () => {
    if (this.state.project !== this.state.projectPrev) {
      this.getProjectVersion();
    }
  };

  getProjectVersion = () => {
    this.setState({
      versionProd: GetProjectVersion(this.state.project, this.state.projectPrev)
        .dataVersionProduction,
      versionDev: GetProjectVersion(this.state.project, this.state.projectPrev)
        .dataVersionDevelopment,
      projectPrev: this.state.project,
    });
  };

  onFormSubmitImport = (e) => {
    e.preventDefault();
    RefreshToken();
    let inputDev = document.getElementById("Import-data-input-version-dev");
    let inputProd = document.getElementById("Import-data-input-version-prod");
    if (inputDev.value && inputProd.value) {
      const fileToUpload = document.getElementById("fileToUpload").files[0];
      const formData = new FormData();
      formData.append("file", fileToUpload);
      formData.append(
        "request",
        new Blob(
          [
            JSON.stringify({
              "data-version-development": inputDev.value,
              "data-version-production": inputProd.value,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
      let path = "/dialog-texts/v2/" + this.state.projectPrev + "/import";
      /*
      let endpoint = "https://talking-camel.addai.cz/api";
      let endpoint = "https://testing-talking-camel.addai.cz/api";
      let endpoint = "https://airbank-talking-camel.addai.cz/api";
      */
     let endpoint = "https://airbank-talking-camel.addai.cz/tc/api";
      let headers = {
        Authorization: "Bearer " + this.props.accessToken,
      };
      let body = formData;
      let method = "POST";
      fetch(endpoint + path, {
        method: method,
        body: body,
        headers: headers,
      });
      ReactDOM.render(
        <Popup type="success" text={"dataImportComplete"} />,
        document.getElementById("popup-window")
      );
    } else {
      ReactDOM.render(
        <Popup type="error" text={"VersionsOrFileMissingImport"} />,
        document.getElementById("popup-window")
      );
    }
  };

  onFileInsert = () => {
    this.setState({
      fileName:
        "File upload path: " + document.getElementById("fileToUpload").value,
    });
  };

  render() {
    return (
      <div className="data-import container">
        <div className="row">
          <div className="col">
            <h4>Data import</h4>
            <h5>Project: {this.state.project}</h5>
          </div>
        </div>

        <form onSubmit={this.onFormSubmitImport}>
          <div className="row">
            <input
              type="file"
              id="fileToUpload"
              className="custom-file-input input rounded"
              onChange={this.onFileInsert}
            />
            <div className="row">
              <div className="col">{this.state.fileName}</div>
            </div>
          </div>
          <div className="row">
            <input
              type="text"
              id="Import-data-input-version-dev"
              className="form-control col"
              placeholder={
                "Current version Development " + this.state.versionDev
              }
              onSubmit={(e) => this.setState({ versionDev: e.target.value })}
            />
            <input
              type="text"
              id="Import-data-input-version-prod"
              className="form-control col"
              placeholder={
                "Current version Production " + this.state.versionProd
              }
              onSubmit={(e) => this.setState({ versionProd: e.target.value })}
            />
          </div>
          <div className="row">
            <button type="submit" className={"btn btn-primary col"}>
              {" "}
              Submit data import
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default DataImport;
