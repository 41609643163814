import React from "react";
import "../SpravaOdpovediNewDialog.scss";
import SpravaOdpovediNewDialogListRow from "../spravaOdpovediNewDialogListRow/SpravaOdpovediNewDialogListRow";

class SpravaOdpovediNewDialogList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  renderPrehled = (props) => {
    let i = 0;
    const dialogNewShort = []
      .concat(this.props.dialogNewShort)
      .sort((a, b) => (a.title > b.title ? 1 : -1))
      .map((dialogNewShort) => {
        i++;
        let dialogTerm = "";
        let searchedTerm = "";
        let dialogNewShortTitle = "";
        if (dialogNewShort.title) {
          dialogTerm = dialogNewShort.title;
          dialogNewShortTitle = dialogNewShort.title;
        } else {
          dialogNewShortTitle = "";
        }
        if (this.props.searchedTerm) {
          searchedTerm = this.props.searchedTerm;
        }
        let dialogNewShortQuestion = dialogNewShort.question
          ? dialogNewShort.question
          : "";
        let dialogNewShortAnswer = dialogNewShort.answer
          ? dialogNewShort.answer
          : "";
        if (dialogTerm.toLowerCase().includes(searchedTerm.toLowerCase())) {
          return (
            <SpravaOdpovediNewDialogListRow
              key={"SODMN" + i}
              userRole={this.props.userRole}
              project={this.props.project}
              accessToken={this.props.accessToken}
              dialogNewShortTitle={dialogNewShortTitle}
              dialogNewShortQuestion={dialogNewShortQuestion}
              dialogNewShortAnswer={dialogNewShortAnswer}
              dialogNewShortId={dialogNewShort.id}
              dialogNewShortCreatedAt={dialogNewShort.createdAt}
              getSODialogNewPreview={this.props.getSODialogNewPreview}
              comments={dialogNewShort.comments}
              username={this.props.username}
              accepted={dialogNewShort.acceptedAt ? true : false}
            />
          );
        }
        return "";
      });
    return dialogNewShort;
  };

  componentDidUpdate = () => {};

  render() {
    return this.renderPrehled();
  }
}

export default SpravaOdpovediNewDialogList;
